import Column from 'components/Column';
import { Body, H4 } from 'components/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
`;

export const FullImage = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledImage = styled.img<{ $float: string }>`
  float: ${({ $float }) => $float};
  ${({ $float }) => ($float === 'left' ? 'margin-right: 16px' : 'margin-left: 16px')};
  width: auto;
  height: 500px;
`;

export const TextsContainer = styled.div`
  overflow: hidden;
  display: inline;
`;

export const Description = styled(H4)`
  overflow-wrap: break-word;

  @media (max-width: 1000px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const GraySection = styled(Column)`
  background: ${({ theme }) => theme.colors.concrete};
  padding: 32px;
`;

export const Block = styled.div`
  overflow: hidden;

  &:before {
    content: '';
    display: table;
    clear: both;
  }
`;
