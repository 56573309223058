import supabase from 'supabase';
import { Dispatch } from 'redux';
import CUADROS_TYPES from './types';
import { getUserId } from 'services/storage';

export const getCuadros =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(CUADROS_TYPES.cuadrosStart());

    const { data, error } = await supabase.from('cuadros').select();

    if (data?.length) dispatch(CUADROS_TYPES.cuadrosSuccess(data));
    else if (error) dispatch(CUADROS_TYPES.cuadrosError(error.message));
  };

export const deleteCuadros =
  (id: number) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(CUADROS_TYPES.cuadrosStart());

    const { data, error } = await supabase
      .from('cuadros')
      .update({ active: false, deleted_from: getUserId(), deleted_at: new Date() })
      .eq('id', id)
      .select();

    if (data?.length) dispatch(CUADROS_TYPES.cuadrosSuccess(data));
    else if (error) dispatch(CUADROS_TYPES.cuadrosError(error.message));
  };

export const updateCuadros =
  (
    id: number,
    name: string,
    description: string,
    id_serie: number,
    label: string,
    price: string,
    discount: number,
    extra_images: string[],
    order: number
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(CUADROS_TYPES.cuadrosStart());

    const { data, error } = await supabase
      .from('cuadros')
      .update({
        name,
        description,
        id_serie,
        label,
        price,
        discount,
        extra_images,
        order,
        updated_from: getUserId(),
        updated_at: new Date(),
      })
      .eq('id', id)
      .select();

    if (data?.length) dispatch(CUADROS_TYPES.cuadrosSuccess(data));
    else if (error) dispatch(CUADROS_TYPES.cuadrosError(error.message));
  };

export const insertCuadro =
  (
    name: string,
    description: string,
    id_serie: number,
    label: string,
    price: string,
    discount: number,
    extra_images: string[],
    order: number
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(CUADROS_TYPES.cuadrosStart());

    const { data, error } = await supabase
      .from('cuadros')
      .insert({
        name,
        description,
        id_serie,
        label,
        price,
        discount,
        extra_images,
        order,
        active: true,
        inserted_from: getUserId(),
        inserted_at: new Date(),
      })
      .select();

    if (data?.length) dispatch(CUADROS_TYPES.cuadrosSuccess(data));
    else if (error) dispatch(CUADROS_TYPES.cuadrosError(error.message));
  };
