import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { send } from '@emailjs/browser';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Button } from 'components/Button';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import Icon, { IconNames } from 'components/Icon';
import { showToast, Toast } from 'components/Toast';
import { CartItem, StyledImage } from 'components/SlidingCart/styled';
import { Body, BodySmall, H4, UtilityText } from 'components/Typography';
import Input, { onChangeTextareaType, onChangeType } from 'components/Input';
import { emailFormat } from 'helpers/regexp';
import { useAppSelector } from 'helpers/hooks';
import { abbreviateNum } from 'hooks/utils';
import { getCuadrosState } from 'selectors/cuadros';
import { clearCartCuadros, getCartCuadros, setCartCuadros } from 'services/storage';
import { CartContainer, CheckoutForm, Container, Content, HiddenContainer } from './styled';

export const CheckoutScreen = () => {
  const form: any = useRef();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [consult, setConsult] = useState('');
  const cuadrosState = useAppSelector(getCuadrosState);

  const currentArrCartCuadros = getCartCuadros()?.split(',') || [];
  const cartItems = cuadrosState.data?.filter(item =>
    currentArrCartCuadros.includes(item.id.toString())
  );

  useEffect(() => {
    if (currentArrCartCuadros.length === 0) navigate('/');
  }, []);

  const handleClick = (id: number) => {
    navigate(`/detail/${id}`);
  };

  const removeFromCart = (id: number) => {
    const arr = currentArrCartCuadros?.filter(item => item !== id.toString());
    if (arr.toString()) setCartCuadros(arr.toString());
    else clearCartCuadros();
    window.location.reload();
  };

  const handleSubmit = () => {
    if (name && surname && email && phone && consult) {
      const cartItemsSummary = cartItems
        ?.map(item => `Nombre: ${item.name}, Precio: U$D${item.price}`)
        .join('\n');

      const templateParams = {
        name,
        surname,
        email,
        phone,
        consult,
        cartItemsSummary,
        cartTotal: `U$D ${abbreviateNum(cartItems?.reduce((sum, cuadro) => sum + parseInt(cuadro.price), 0))}`,
      };

      send('service_eij9ist', 'template_t31lzmf', templateParams, '_guNqvHSvZ9NRyRBw').then(error =>
        console.log(error.text)
      );
      showToast('Pedido realizado exitosamente! Se contactarán a la brevedad.', 'success', () =>
        cleanForm()
      );
    } else {
      console.log('Faltan datos');
    }
  };

  const cleanForm = () => {
    setName('');
    setSurname('');
    setEmail('');
    setPhone('');
    setConsult('');
    clearCartCuadros();
    navigate('/');
  };

  if (currentArrCartCuadros.length === 0) return null;
  return (
    <>
      <Header />
      <Toast />
      <Container>
        <Content>
          <CheckoutForm ref={form}>
            <Body>DATOS DE CONTACTO</Body>
            <Spacing $size={4} />
            <Input
              id="email"
              name="email"
              placeholder="Email *"
              value={email}
              textOnChange={(e: onChangeType) => setEmail(e.target.value)}
              fullWidth
              withErrors={!emailFormat.test(email) && !!email}
              leyend={!emailFormat.test(email) && !!email ? 'El email ingresado no es válido.' : ''}
            />
            <Spacing $size={16} />
            <Body>MEDIO DE PAGO Y ENTREGA</Body>
            <BodySmall>*A convenir con el cliente por mail</BodySmall>
            <Spacing $size={16} />
            <Body>DATOS DE FACTURACIÓN</Body>
            <Spacing $size={4} />
            <Input
              id="name"
              name="name"
              placeholder="Nombre"
              value={name}
              textOnChange={(e: onChangeType) => setName(e.target.value)}
              fullWidth
            />
            <Spacing $size={16} />
            <Input
              id="surname"
              name="surname"
              placeholder="Apellido"
              value={surname}
              textOnChange={(e: onChangeType) => setSurname(e.target.value)}
              fullWidth
            />
            <Spacing $size={16} />
            <Input
              id="phone"
              name="phone"
              placeholder="Teléfono"
              value={phone}
              textOnChange={(e: onChangeType) => setPhone(e.target.value)}
              fullWidth
            />
            <Spacing $size={16} />
            <Body>NOTAS DE PEDIDO</Body>
            <Spacing $size={4} />
            <Input
              id="consult"
              name="consult"
              placeholder=""
              value={consult}
              textareaOnChange={(e: onChangeTextareaType) => setConsult(e.target.value)}
              fullWidth
              textarea
            />
          </CheckoutForm>
          <CartContainer>
            {cartItems?.map(item => (
              <CartItem key={item.id}>
                <Row $justifyContent="space-between" $alignItems="center">
                  <Row $pressable onClick={() => handleClick(item.id)}>
                    <StyledImage src={require(`../../assets/img/cuadros/cuadro_${item.id}.jpg`)} />
                    <Spacing $vertical $size={12} />
                    <Column>
                      <H4>{item.name}</H4>
                      <BodySmall $color={theme.colors.gulfStream}>
                        U$D{abbreviateNum(parseInt(item.price))}
                      </BodySmall>
                    </Column>
                  </Row>
                  <Icon
                    name={IconNames.Trash}
                    color={theme.colors.errorRed}
                    size={18}
                    style={{ cursor: 'pointer' }}
                    onClick={() => removeFromCart(item.id)}
                  />
                </Row>
              </CartItem>
            ))}
            <CartItem>
              <Row $justifyContent="space-between" $alignItems="center">
                <H4>Subtotal</H4>
                <BodySmall $color={theme.colors.gulfStream}>
                  U$D
                  {abbreviateNum(
                    cartItems?.reduce((sum, cuadro) => sum + parseInt(cuadro.price), 0)
                  )}
                </BodySmall>
              </Row>
            </CartItem>
            <CartItem>
              <Row $justifyContent="space-between" $alignItems="center">
                <H4>Total</H4>
                <BodySmall $color={theme.colors.gulfStream}>
                  U$D
                  {abbreviateNum(
                    cartItems?.reduce((sum, cuadro) => sum + parseInt(cuadro.price), 0)
                  )}
                </BodySmall>
              </Row>
            </CartItem>
            <HiddenContainer $showInDesktop={true}>
              <Spacing $size={16} />
              <Button
                $disabled={!name || !surname || !email || !emailFormat.test(email) || !phone}
                onClick={handleSubmit}
              >
                Realizar pedido
              </Button>
            </HiddenContainer>
          </CartContainer>
        </Content>
        <HiddenContainer $showInDesktop={false}>
          <Spacing $size={16} />
          <Button
            $disabled={!name || !surname || !email || !emailFormat.test(email) || !phone}
            onClick={handleSubmit}
          >
            Realizar pedido
          </Button>
        </HiddenContainer>
      </Container>
      <Footer />
    </>
  );
};
