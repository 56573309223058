import { createActions } from 'reduxsauce';

export interface CuadrosState {
  data: ICuadrosData[];
  isLoading: boolean;
  error: string;
}
export interface ICuadrosData {
  id: number;
  id_serie: number;
  name: string;
  description: string;
  label: string;
  price: string;
  extra_images: number[];
  discount: number;
  order: number;
  active: boolean;
}

export interface CuadrosTypes {
  CUADROS: 'CUADROS';
  CUADROS_START: 'CUADROS_START';
  CUADROS_SUCCESS: 'CUADROS_SUCCESS';
  CUADROS_ERROR: 'CUADROS_ERROR';
  CUADROS_CLEAN_UP: 'CUADROS_CLEAN_UP';
}

const { Types, Creators } = createActions<CuadrosTypes>({
  cuadros: ['data'],
  cuadrosStart: null,
  cuadrosSuccess: ['data'],
  cuadrosError: ['error'],
  cuadrosCleanUp: null,
});

export { Types };

export default Creators;
