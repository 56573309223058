import React, { FC } from 'react';
import classnames from 'classnames';
import { matchSorter } from 'match-sorter';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import Icon, { IconNames } from 'components/Icon';
import theme from 'config/theme';
import { IconContainer, TableCell, TableHeader } from 'screens/Dashboard/styled';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { useAppSelector } from 'helpers/hooks';
import { getSeriesState } from 'selectors/series';

export type onChangeType = React.ChangeEvent<HTMLInputElement>;

interface Props {
  columns?: any[];
  data: any;
  pageSize?: number;
  withPagination?: boolean;
  withDownloadCSV?: boolean;
  withColumnFilter?: boolean;
  handleDelete: (id: number) => void;
  handleEdit: (id: number) => void;
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        placeholder={`Search ${count} records...`}
        type="text"
        onChange={(e: onChangeType) => {
          setFilter(e.target.value || undefined);
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = val => !val;

export const CustomTable: FC<Props> = ({
  columns,
  data,
  pageSize,
  withPagination,
  handleDelete,
  handleEdit,
}) => {
  const seriesState = useAppSelector(state => getSeriesState(state));

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize: pageSize || 10, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div>
        <div className="ReactTable -striped -highlight oathBlue-pagination">
          <table {...getTableProps()} className="table-scroll">
            <thead className="rt-thead -header">
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr" key={i}>
                  {headerGroup.headers.map((column, key) => (
                    <th
                      key={key}
                      className={classnames('rt-th rt-resizable-header', {
                        '-cursor-pointer': headerGroup.headers[key].Header !== 'Actions',
                        '-sort-asc': column.isSorted && !column.isSortedDesc,
                        '-sort-desc': column.isSorted && column.isSortedDesc,
                      })}
                    >
                      <TableHeader
                        className="oath-eyebrow inkBlue"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                      </TableHeader>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rt-tbody">
              {page
                .filter(item => item.original.active)
                .map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={classnames(
                        'rt-tr',
                        { ' -odd': i % 2 === 0 },
                        { ' -even': i % 2 === 1 }
                      )}
                    >
                      {row.cells.map((cell, key) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="rt-td oath-bodysmall inkBlue"
                            key={key}
                          >
                            <TableCell>
                              {cell.column.id === 'actions' ? (
                                <Row>
                                  <IconContainer
                                    $type="edit"
                                    onClick={() => handleEdit(cell.row.original.id)}
                                  >
                                    <Icon
                                      name={IconNames.Pen}
                                      color={theme.colors.white}
                                      size={16}
                                    />
                                  </IconContainer>
                                  <Spacing $vertical $size={8} />
                                  <IconContainer
                                    $type="delete"
                                    onClick={() => handleDelete(cell.row.original.id)}
                                  >
                                    <Icon
                                      name={IconNames.Trash}
                                      color={theme.colors.white}
                                      size={16}
                                    />
                                  </IconContainer>
                                </Row>
                              ) : cell.column.id === 'id_serie' ? (
                                seriesState?.data?.find(
                                  serie => serie.id === cell.row.original.id_serie
                                )?.title
                              ) : (
                                cell.render('Cell')
                              )}
                            </TableCell>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <br />
          {withPagination && (
            <div className="pagination-bottom">
              <div className="-pagination">
                <div>
                  <div className="-previous">
                    <button
                      type="button"
                      onClick={previousPage}
                      disabled={!canPreviousPage}
                      className="-btn"
                    >
                      Previous
                    </button>
                  </div>
                </div>
                <div>
                  <div className="-next">
                    <button
                      type="button"
                      onClick={nextPage}
                      disabled={!canNextPage}
                      className="-btn"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';

export const tableButton = (handlePress, type, id) => {
  return (
    <div className="padding0">
      <div className="table-action-link inkBlue" onClick={() => handlePress(type, id)}>
        <Icon name={buttonTypes[type].icon} color={theme.colors.gulfStream} />
        <span>{type}</span>
      </div>
    </div>
  );
};

export const buttonTypes = {
  edit: { icon: IconNames.Edit },
  delete: { icon: IconNames.Trash },
  preview: { icon: IconNames.Eye },
  duplicate: { icon: IconNames.Copy },
};
