import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cuadrosColumns, faqsColumns, seriesColumns } from 'constants/tables';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Button } from 'components/Button';
import { H1, H2 } from 'components/Typography';
import { CustomTable } from 'components/CustomTable';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUrlParams } from 'hooks/utils';
import { handleSession } from 'hooks/handleSession';
import { getFaqsState } from 'selectors/faqs';
import { getSeriesState } from 'selectors/series';
import { getCuadrosState } from 'selectors/cuadros';
import { getUserId } from 'services/storage';
import { IFaqsData } from 'store/faqs/types';
import { ISeriesData } from 'store/series/types';
import { ICuadrosData } from 'store/cuadros/types';
import { deleteFaqs, getFaqs } from 'store/faqs/actions';
import { deleteSeries, getSeries } from 'store/series/actions';
import { deleteCuadros, getCuadros } from 'store/cuadros/actions';
import { Container, Menu } from './styled';

export const DashboardScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { urlPage } = getUrlParams();
  const { isExpired } = handleSession();
  const [pageData, setPageData] = useState<ICuadrosData[] | ISeriesData[] | IFaqsData[]>();
  const [pageName, setPageName] = useState('');
  const [columnsSelected, setColumnsSelected] = useState<{ Header: string; accessor: string }[]>();
  const { faqsState, seriesState, cuadrosState } = useAppSelector(state => ({
    faqsState: getFaqsState(state),
    seriesState: getSeriesState(state),
    cuadrosState: getCuadrosState(state),
  }));

  useEffect(() => {
    if (!getUserId() || isExpired()) navigate('admin');
  }, []);

  useEffect(() => {
    if (urlPage === 'faqs' && !faqsState.isLoading && faqsState.data) {
      setPageData(faqsState.data.filter(faqs => faqs.active));
    }
  }, [faqsState]);

  useEffect(() => {
    if (urlPage === 'series' && !seriesState.isLoading && seriesState.data) {
      setPageData(seriesState.data.filter(series => series.active));
    }
  }, [seriesState]);

  useEffect(() => {
    if (urlPage === 'cuadros' && !cuadrosState.isLoading && cuadrosState.data) {
      setPageData(cuadrosState.data.filter(cuadros => cuadros.active));
    }
  }, [cuadrosState]);

  useEffect(() => {
    dispatch(getFaqs());
    dispatch(getCuadros());
    dispatch(getSeries());
    if (urlPage === 'cuadros') {
      setColumnsSelected(cuadrosColumns);
      setPageName('Cuadros');
    }
    if (urlPage === 'series') {
      setColumnsSelected(seriesColumns);
      setPageName('Series');
    }
    if (urlPage === 'faqs') {
      setColumnsSelected(faqsColumns);
      setPageName('Preguntas Frecuentes');
    }
  }, [urlPage]);

  const handleDelete = (id: number) => {
    if (confirm(`Seguro que quieres eliminar de la tabla ${pageName} el elemento ${id}?`)) {
      if (urlPage === 'faqs') dispatch(deleteFaqs(id));
      if (urlPage === 'series') dispatch(deleteSeries(id));
      if (urlPage === 'cuadros') dispatch(deleteCuadros(id));
    }
  };

  const handleEdit = (id: number) => {
    navigate(`/page_detail?p=${urlPage}&id=${id}`);
  };

  return (
    <Container>
      <Menu>
        <Link to="/dashboard?p=cuadros">Cuadros</Link>
        <Link to="/dashboard?p=series">Series</Link>
        <Link to="/dashboard?p=faqs">Preguntas Frecuentes</Link>
      </Menu>
      <H1>PANEL DE CONTROL</H1>
      <Spacing $size={12} />
      {urlPage && pageName && (
        <>
          <H2>Lista de {pageName}</H2>
          <Spacing $size={16} />
          <Button style={{ maxWidth: 300 }} to={`/page_detail?p=${urlPage}`}>
            Crear {pageName}
          </Button>
        </>
      )}
      {urlPage && pageData && pageData.length > 0 && (
        <>
          <Spacing $size={16} />
          <CustomTable
            data={pageData}
            columns={columnsSelected}
            withPagination
            withDownloadCSV
            withColumnFilter
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </>
      )}
    </Container>
  );
};
