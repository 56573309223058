import { Row } from 'components/Row';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  background: ${({ theme }) => theme.colors.softGray};
  left: 0;
  right: 0;
  height: 100%;
`;

export const Menu = styled(Row)`
  margin-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  padding: 8px;
  font-size: 20px;
  line-height: 30px;
  ${({ theme }) => theme.fonts.semiBold};
  text-align: left;
`;

export const TableCell = styled.td`
  align-content: flex-start;
  padding: 8px;
  font-size: 15px;
  line-height: 20px;
  ${({ theme }) => theme.fonts.light};
  text-align: left;
  max-width: 400px;
`;

export const TR = styled.tr<{ $even: boolean }>`
  background: ${({ theme, $even }) => ($even ? theme.colors.white : theme.colors.softGray)};

  &:hover {
    background: ${({ theme }) => theme.colors.silver};
  }
`;

export const IconContainer = styled.button<{ $type: string }>`
  background: ${({ theme, $type }) =>
    $type === 'edit' ? theme.colors.success : theme.colors.errorRed};
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: ${({ theme, $type }) =>
      $type === 'edit' ? theme.shadows.success(0.8) : theme.shadows.errorRed(0.8)};
  }
`;
